<template>
  <div class="user-my-demand" v-wechat-title="'我的国产替代迁移需求'">
    <van-list
      v-if="demandList != ''"
      v-model="loading"
      :finished="finished"
      finished-text="暂无更多数据"
      @load="onLoad"
    >
      <div class="user-my-demand-list">
        <div v-for="(item,index) in demandList" :key="index" @click="demandInfo(item.id,item.orderStatus,item)">
          <span :class="{'1':'sqz','2':'sqz','3':'ysl','4':'yhf','5':''}[item.orderStatus]">{{ item.orderStatus | dict('ORDER_STATUS') }}</span>
          <strong>{{ item.adaptationType == '1'?'硬件产品':'模组' }}</strong>
          <p><span>设备名称：</span><span>{{ item.title.replace(/,/g," | ") }}</span></p>
          <div>
            <span>联系人：{{ item.contactPerson }}</span>
            <span>提交时间：{{ item.appliedTime | moment('YYYY-MM-DD') }}</span>
          </div>
        </div>
      </div>
    </van-list>
    <van-empty
      v-if="isEmpty"
      class="empty-image"
      :image="require('@/assets/img/4886@2x.png')"
      description="暂无更多数据"
    />
  </div>
</template>

<script>
import { getMyOrderList } from '@/api/order'

export default {
  data () {
    return {
      page: {
        current: 1,
        size: 10,
        pageSize: 1
      },
      loading: false,
      finished: false,
      demandList: [],
      isEmpty:false
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    demandInfo(id,status,val){
      if(status != '1'){
        this.$router.push(`/myDemand/info?id=${id}`)
      }else{
        localStorage.setItem('orderVal', JSON.stringify(val))
        this.$router.push({
          name:'demandMove',
          query:{
            orderId:id
          }
        })
      }
    },
    onLoad () {
      if (!this.demandList || !this.demandList.length) {
        this.finished = true;
        return
      }
      if (this.page.pageSize === this.page.current) {
        this.finished = true
        return
      }
      this.page.current++
      this.loading = true
      this.loadData()
    },
    // 解决方案列表
    loadData () {
      const { page } = this
      const data = {}
      getMyOrderList(page, data).then((res) => {
        this.loading = false
        this.demandList = [...this.demandList, ...res.records]
        this.page.pageSize = res.pages
        this.finished = false
        if(this.demandList == ''){
          this.isEmpty = true
        }else{
          this.isEmpty = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-my-demand{
  min-height: calc(100vh - 56px);
  background: #F6F6F6;
}
.user-my-demand-list{
  padding: 16px 16px 0;
  >div{
    padding: 20px 16px 0;
    background: #FFFFFF;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    margin-bottom: 12px;
    &:last-child{
      margin-bottom: 0;
    }
    >span{
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      height: 24px;
      background: rgba(191,191,191,0.1500);
      border-radius: 4px;
      padding: 0 6px;
      line-height: 24px;
      font-size: 12px;
      color: #BFBFBF;
      &.sqz{
        color: #FEBE7C;
        background: rgba(254,190,124,0.1500);
      }
      &.ysl{
        color: #52AFFC;
        background: rgba(82,175,252,0.1500);
      }
      &.yhf{
        color: #37D2B3;
        background: rgba(55,210,179,0.1500);
      }
    }
    >strong{
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      color: #000000;
      display: block;
      margin-bottom: 12px;
    }
    >p{
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 12px;
      display: flex;
      >span:first-child{
        color: #999;
        white-space: nowrap;
      }
      >span:last-child{
        color: #262626;
        flex: 1;
      }
    }
    >div{
      border-top: 1px solid #eee;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      >p{
        display: flex;
        align-items: center;
        font-size: 12px;
        >span{
          width: 24px;
          height: 24px;
          margin-right: 8px;
          >img{
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
      >span{
        font-size: 12px;
        color: #999999;
      }
    }
  }
}
</style>
<style>
.empty-image .van-empty__image{
  width: 94px;
  height: 81px;
}
</style>
